<template>
    <section class="filters">
      <b-form-group class="search-patient">
        <label for="search-by-patient">Item</label>
        <b-form-input
          placeholder="Buscar código ou descrição..."
          v-model="filters.query"
          debounce="300"
        />
      </b-form-group>
      <b-form-group class="search-situation">
        <label for="search-by-situation">Situação</label>
        <multiselect
          id="search-by-situation"
          placeholder="Selecionar..."
          v-model="filters.situation"
          :options="situationsOptions"
          :searchable="true"
          :allow-empty="false"
          :showLabels="false"
          class="with-border"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template slot="noOptions">Nenhuma guia encontrada</template>
        </multiselect>
      </b-form-group>
    </section>
  </template>
  <script>
  export default {
    name: 'guides-filter',
    components: {
      ChevronDown: () => import('@/assets/icons/chevron-down.svg')
    },
    data() {
      return {
        filters: {
          patient: null,
          situation: null,
          guideNumber: null
        },
        guides: [],
        originalRows: [],
        situations: [
          'Pago',
          'Pago parcialmente',
          'Glosado',
        ]
      }
    },
    computed: {
      situationsOptions() {
        return ['Pago', 'Pago parcialmente', 'Glosado']
      }
    },
    watch: {
      filters: {
        handler(value) {
          this.$emit('changeFilter', value)
        },
        deep: true
      }
    },
    
  }
  </script>